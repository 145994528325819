
import Vue, { PropType } from "vue"
import { debounce, toQueryString } from "@evercam/shared/utils"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { schedules } from "@evercam/ui"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "TimelineThumbnails",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    cameraExid: {
      type: String,
      required: true,
    },
    dateInterval: {
      type: Object as PropType<{
        from: string
        to: string
      }>,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    translation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isDebounced: false,
      thumbnailsSrcs: [],
      containerWidthPercent: 100,
      thumbnailWidthPercent: 100,
      thumbnailWidth: 90,
      totalTranslation: 0,
      initialIntervalDuration: 0,
      containerHeight: 0,
      hoveredThumbnail: null,
      hoveredThumbnailIndex: 0,
    }
  },
  computed: {
    tooltipStyle() {
      const xTranslation = [0, 12, 24, 35, 47, 59, 70, 82, 94, 105, 111]

      return {
        transform: `translateY(-100%) translateX(${
          xTranslation[this.hoveredThumbnailIndex] || 111
        }%)`,
      }
    },
    containerStyle() {
      return {
        height: this.containerHeight ? `${this.containerHeight}px` : "auto",
        width: this.isDebounced
          ? `${this.containerWidthPercent}% !important`
          : "100%",
        transform: this.isDebounced
          ? `translateX(${this.totalTranslation}px)`
          : "none",
      }
    },
    thumbnailStyle() {
      return { width: this.thumbnailWidthPercent + "%" }
    },
    intervalDuration() {
      return (
        new Date(this.dateInterval.to).getTime() -
        new Date(this.dateInterval.from).getTime()
      )
    },
  },
  watch: {
    intervalDuration(newInterval, oldInterval) {
      if (!this.initialIntervalDuration) {
        this.initialIntervalDuration = oldInterval
      }
      this.containerWidthPercent =
        (100 * this.initialIntervalDuration) / newInterval
      this.thumbnailWidthPercent = 100 / this.thumbnailsSrcs.length
    },
    translation(v) {
      this.totalTranslation = v + this.totalTranslation
    },
    dateInterval() {
      if (!this.dateInterval.from || !this.dateInterval.to) {
        return
      }
      this.isDebounced = true
      this.updateThumbnails()
    },
  },
  methods: {
    getPreviewSrc(src) {
      return src.replace(":360", ":720")
    },
    // @ts-ignore
    updateThumbnails: debounce(async function (this: this) {
      const containerWidth = this.$refs.container.getBoundingClientRect().width
      const thumbnailsCount = Math.floor(containerWidth / this.thumbnailWidth)
      const DAY = 86_400_000
      const intervalDays = this.intervalDuration / DAY
      const schedule = JSON.stringify(
        intervalDays > 5 ? schedules.workingHours : schedules.continuous
      )

      const { snapshots } = await EvercamApi.timelapse.getSnapshotRange(
        this.cameraExid,
        {
          from: this.dateInterval.from,
          to: this.dateInterval.to,
          schedule,
          count: thumbnailsCount,
        }
      )

      this.thumbnailsSrcs = []
      this.$nextTick(() => {
        this.thumbnailsSrcs = snapshots.map((timestamp) => {
          return this.$imgproxy.get360pResizedImageUrl(
            this.getSnapshotDefaultSrc(timestamp)
          )
        })
        this.initialIntervalDuration = this.intervalDuration
        this.isDebounced = false
        this.totalTranslation = 0
      })
    }, 200),
    updateContainerHeight({
      contentRect,
    }: {
      contentRect: { height: number }
    }) {
      if (!this.containerHeight || contentRect.height > this.containerHeight) {
        this.containerHeight = contentRect.height
      }
    },
    getSnapshotDefaultSrc(createdAt: string) {
      if (!this.cameraExid) {
        return
      }
      const queryParams = {
        authorization: this.token,
        view: true,
      }

      return `${this.$config.public.apiURL}/cameras/${
        this.cameraExid
      }/recordings/snapshots/${createdAt}?${toQueryString(queryParams)}`
    },
  },
})
